import React, { PureComponent } from "react";
import { Device } from "@tvg/ts-types/Device";
import { SeoArea } from "@tvg/design-system/web";

import { SeoContainer, SeoFooterTitle } from "./styled-components";

export default class SeoFooter extends PureComponent {
  static defaultProps = {
    message: "",
    title: "Horse Racing Betting",
    device: Device.MOBILE,
    addBottomMargin: false
  };

  render() {
    return <SeoArea title={this.props.title} message={this.props.message} />;
  }
}
